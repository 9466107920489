import React, { useState } from "react"

export const ActivePageContext = React.createContext()

function ActivePageProvider({ children }) {
  const [active, setActive] = useState("home")
  return (
    <ActivePageContext.Provider value={{ active, setActive }}>
      {children}
    </ActivePageContext.Provider>
  )
}

export default ActivePageProvider
