import React, { useState } from "react"

export const ProductContext = React.createContext()

function ProductProvider({ children }) {
  const [isLoading, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [filtered, setFiltered] = useState([])

  return (
    <ProductContext.Provider
      value={{
        isLoading,
        setLoading,
        products,
        setProducts,
        filtered,
        setFiltered,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export default ProductProvider
