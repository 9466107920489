export const handleCategory = (products, category, selectedBrands) => {
  let sorted = [...products]

  if (selectedBrands.length) {
    sorted = sorted.filter(product =>
      selectedBrands.includes(product.productBrand)
    )
  }

  if (!category || category === "none") {
    return sorted
  }

  const filter = sorted.filter(prod => {
    switch (category) {
      case "T-shirts":
        return (
          prod.productName.toLowerCase().match("t-shirt") ||
          prod.productCategory.toLowerCase().match("t-shirt") ||
          prod.productName.toLowerCase().match("tshirt") ||
          prod.productCategory.toLowerCase().match("tshirt")
        )
      case "Trøjer":
        return (
          prod.productCategory.toLowerCase().match("trøje") ||
          prod.productName.toLowerCase().match("trøje") ||
          prod.productName.toLowerCase().match("sweater") ||
          prod.productName.toLowerCase().match("sweatshirt") ||
          prod.productName.toLowerCase().match("hoodie")
        )
      case "Jakker":
        return (
          prod.productCategory.toLowerCase().match("jakke") ||
          prod.productCategory.toLowerCase().match("frakke") ||
          prod.productName.toLowerCase().match("jakke") ||
          prod.productName.toLowerCase().match("frakke") ||
          prod.productName.toLowerCase().match("coat")
        )
      case "Skjorter":
        return (
          prod.productName.toLowerCase().match("skjorte") ||
          prod.productCategory.toLowerCase().match("skjorte")
        )
      case "Sko":
        return (
          prod.productCategory.toLowerCase().match("sko") ||
          prod.productName.toLowerCase().match("sko") ||
          prod.productName.toLowerCase().match("støvler") ||
          prod.productName.toLowerCase().match("boots") ||
          prod.productName.toLowerCase().match("sandal") ||
          (prod.productName.toLowerCase().match("sneakers") &&
            !prod.productName.toLowerCase().match("viskose") &&
            !prod.productName.toLowerCase().match("skov"))
        )
      case "Accessories":
        return (
          prod.productCategory.toLowerCase().match("accessori") ||
          prod.productName.toLowerCase().match("accessori") ||
          prod.productName.toLowerCase().match("smykke") ||
          prod.productName.toLowerCase().match("halskæde") ||
          prod.productName.toLowerCase().match("armbånd") ||
          prod.productName.toLowerCase().match("ørering") ||
          prod.productName.toLowerCase().match("bracelet") ||
          prod.productName.toLowerCase().match("earring")
        )
      case "Solbriller":
        return (
          prod.productName.toLowerCase().match("solbrille") ||
          prod.productCategory.toLowerCase().match("solbrille")
        )
      case "Bukser":
        return (
          !prod.productName.toLowerCase().match("versace jeans") &&
          (prod.productName.toLowerCase().match("bukser") ||
            prod.productName.toLowerCase().match("jeans") ||
            prod.productName.toLowerCase().match("chino") ||
            prod.productCategory.toLowerCase().match("bukser") ||
            prod.productCategory.toLowerCase().match("jeans"))
        )
      case "Shorts":
        return (
          prod.productCategory.toLowerCase().match("shorts") ||
          (prod.productName.toLowerCase().match("shorts") &&
            !prod.productName.toLowerCase().match("boxershorts"))
        )
      case "Tasker":
        return (
          prod.productName.toLowerCase().match("taske") ||
          prod.productCategory.toLowerCase().match("taske")
        )
      case "Undertøj":
        return (
          prod.productCategory.toLowerCase().match("undertøj") ||
          prod.productName.toLowerCase().match("boxershorts") ||
          prod.productName.toLowerCase().match("undertøj") ||
          prod.productName.toLowerCase().match("underbukser")
        )
      default:
        return null
    }
  })

  return filter
}
