import React, { useReducer } from "react"
import reducer, { initialState } from "../reducer"

export const FilterContext = React.createContext()

function FilterProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <FilterContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export default FilterProvider
