import React, { useState } from "react"

export const LoadingContext = React.createContext()

function LoadingProvider({ children }) {
  const [loadingProducts, setLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{ loadingProducts, setLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
