import React, { useEffect, useState } from "react"

export const FavoritesContext = React.createContext()

function FavoritesProvider({ children }) {
  const [favorites, setFavorites] = useState([])

  useEffect(() => {
    if (typeof window === undefined) return;
    const getFavorites = JSON.parse(window.localStorage.getItem("favorite")) || [];
    setFavorites(getFavorites)
  }, [])

  return (
    <FavoritesContext.Provider value={{ favorites, setFavorites }}>
      {children}
    </FavoritesContext.Provider>
  )
}

export default FavoritesProvider
