import { handleCategory } from "./handleCategory"
export const handleBrands = (products, brands) => {
  let sorted = [...products]

  if (!brands.length) {
    return sorted
  }

  console.log('Brands', brands, 'Prod', sorted)
  return sorted.filter(product => brands.includes(product.productBrand))
}
