export const handleSearch = (products, input) => {
  if (!input || !input.length) {
    return products
  }

  return products.filter(
    prod =>
      prod.productName.toLowerCase().match(input.toLowerCase()) ||
      prod.productBrand?.toLowerCase().match(input.toLowerCase())
  )
}
