import React, { useState } from "react"

export const GenderContext = React.createContext()

function GenderProvider({ children }) {
  const [gender, setGender] = useState(null)

  return (
    <GenderContext.Provider value={{ gender, setGender }}>
      {children}
    </GenderContext.Provider>
  )
}

export default GenderProvider
