import React, { useState } from "react"
import { ThemeProvider as Theme } from "styled-components"

export const ThemeContext = React.createContext()

const themeStyling = {
  colors: {
    green: "#ff6347",
    tomato: "#ff6347",
    black: "#424242",
    lightgrey: "#fcfcfc",
    gradient:
      "linear-gradient(90deg, rgba(125,171,127,1) 0%, rgba(163,171,125,1) 100%)",
    beige: "#a3ab7d",
  },
  sidebarWidth: "320px",
}

function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(themeStyling)

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Theme theme={theme}>{children}</Theme>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
