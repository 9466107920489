import React, { useState } from "react"

export const BrandsContext = React.createContext()

function BrandsProvider({ children }) {
  const [brands, setBrands] = useState([])

  return (
    <BrandsContext.Provider value={{ brands, setBrands }}>
      {children}
    </BrandsContext.Provider>
  )
}

export default BrandsProvider
