import React, { useContext, useState } from "react"

export const SortContext = React.createContext()

function SortingProvider({ children }) {
  const [sortBy, setSorting] = useState("relevans")

  return (
    <SortContext.Provider value={{ sortBy, setSorting }}>
      {children}
    </SortContext.Provider>
  )
}

export default SortingProvider
