import React from "react"
import ThemeProvider from "./ThemeProvider"
import ActivePageProvider from "./ActivePage"
import GenderProvider from "./GenderProvider"
import ProductProvider from "./ProductProvider"
import FilterProvider from "./FilterProvider"
import BrandsProvider from "./BrandsProvider"
import FavoritesProvider from "./MyFavorites"
import LoadingProvider from "./LoadingProducts"
import SortingProvider from "./SortingProvider"

function Provider({ children }) {
  return (
    <ThemeProvider>
      <BrandsProvider>
        <ProductProvider>
          <FilterProvider>
            <SortingProvider>
              <FavoritesProvider>
                <GenderProvider>
                  <LoadingProvider>
                    <ActivePageProvider>{children}</ActivePageProvider>
                  </LoadingProvider>
                </GenderProvider>
              </FavoritesProvider>
            </SortingProvider>
          </FilterProvider>
        </ProductProvider>
      </BrandsProvider>
    </ThemeProvider>
  )
}

export default Provider
