import { handleSearch } from "../utils/handleSearch"
import { handleRange } from "../utils/handleRange"

export const initialState = {
  resetState: false,
  products: [],
  relevans: [],
  lowestPrice: [],
  highestPrice: [],
  relevansByBrands: [],
  lowestByBrands: [],
  highestByBrands: [],
  selectedBrands: [],
  pageNumber: 0,
  sortBy: 'relevans'
}

const reducer = (state, action) => {
  // Reset products when changing category
  if (action.resetState) {
    return {
      ...initialState,
    }
  }

  if (action.products) {
    return {
      ...state,
      selectedBrands: action.selectedBrands || state.selectedBrands,
      sortBy: action.sortBy || state.sortBy,
      products: action.products
    }
  }

  if (action.resetState === false) {
    // First render
    return {
      ...state,
      products: action.relevans,
      relevans: action.relevans,
      lowestPrice: action.lowestPrice,
      highestPrice: action.highestPrice,
      selectedBrands: [],
    }
  }

  // if (action.selectedBrands) {
  //   if (state.selectedBrands.length && !action.selectedBrands.length) {
  //     if (state.sortedByRelevans) {
  //       return {
  //         ...state,
  //         products: state.relevans,
  //         selectedBrands: [],
  //       }
  //     }

  //     if (state.sortedByLowest) {
  //       return {
  //         ...state,
  //         products: state.lowestPrice,
  //         selectedBrands: [],
  //       }
  //     }

  //     if (state.sortedByHighest) {
  //       return {
  //         ...state,
  //         products: state.highestPrice,
  //         selectedBrands: [],
  //       }
  //     }
  //   }

  //   if (state.sortedByLowest) {
  //     return {
  //       ...state,
  //       products: action.lowestByBrands,
  //       relevansByBrands: action.relevansByBrands,
  //       lowestByBrands: action.lowestByBrands,
  //       highestByBrands: action.highestByBrands,
  //       selectedBrands: action.selectedBrands,
  //     }
  //   }

  //   if (state.sortedByHighest) {
  //     return {
  //       ...state,
  //       products: action.highestByBrands,
  //       relevansByBrands: action.relevansByBrands,
  //       lowestByBrands: action.lowestByBrands,
  //       highestByBrands: action.highestByBrands,
  //       selectedBrands: action.selectedBrands,
  //     }
  //   }

  //   return {
  //     ...state,
  //     products: action.relevansByBrands,
  //     relevansByBrands: action.relevansByBrands,
  //     lowestByBrands: action.lowestByBrands,
  //     highestByBrands: action.highestByBrands,
  //     selectedBrands: action.selectedBrands,
  //   }
  // }

  // if (action.relevans && action.relevans.length) {
  //   if (state.sortedByLowest) {
  //     return {
  //       ...state,
  //       products: action.lowestPrice,
  //       relevans: action.relevans,
  //       lowestPrice: action.lowestPrice,
  //       highestPrice: action.highestPrice,
  //     }
  //   }

  //   if (state.sortedByHighest) {
  //     return {
  //       ...state,
  //       products: action.highestPrice,
  //       relevans: action.relevans,
  //       lowestPrice: action.lowestPrice,
  //       highestPrice: action.highestPrice,
  //     }
  //   }

  //   return {
  //     ...state,
  //     products: action.relevans,
  //     relevans: action.relevans,
  //     lowestPrice: action.lowestPrice,
  //     highestPrice: action.highestPrice,
  //   }
  // }

  // if (action.relevansByBrands) {
  //   if (state.sortedByLowest) {
  //     return {
  //       ...state,
  //       products: action.lowestByBrands,
  //       relevansByBrands: action.relevansByBrands,
  //       lowestByBrands: action.lowestByBrands,
  //       highestByBrands: action.highestByBrands,
  //     }
  //   }

  //   if (state.sortedByHighest) {
  //     return {
  //       ...state,
  //       products: action.highestByBrands,
  //       relevansByBrands: action.relevansByBrands,
  //       lowestByBrands: action.lowestByBrands,
  //       highestByBrands: action.highestByBrands,
  //     }
  //   }

  //   return {
  //     ...state,
  //     products: action.relevansByBrands,
  //     relevansByBrands: action.relevansByBrands,
  //     lowestByBrands: action.lowestByBrands,
  //     highestByBrands: action.highestByBrands,
  //   }
  // }

  // if (action.sortedByRelevans) {
  //   if (state.selectedBrands.length) {
  //     return {
  //       ...state,
  //       products: state.relevansByBrands,
  //       sortedByLowest: false,
  //       sortedByHighest: false,
  //       sortedByRelevans: true,
  //     }
  //   }
  //   return {
  //     ...state,
  //     products: state.relevans,
  //     sortedByLowest: false,
  //     sortedByHighest: false,
  //     sortedByRelevans: true,
  //   }
  // }

  // if (action.sortedByLowest) {
  //   if (state.selectedBrands.length) {
  //     return {
  //       ...state,
  //       products: state.lowestByBrands,
  //       sortedByLowest: true,
  //       sortedByHighest: false,
  //       sortedByRelevans: false,
  //     }
  //   }
  //   return {
  //     ...state,
  //     products: state.lowestPrice,
  //     sortedByLowest: true,
  //     sortedByHighest: false,
  //     sortedByRelevans: false,
  //   }
  // }

  // if (action.sortedByHighest) {
  //   if (state.selectedBrands.length) {
  //     return {
  //       ...state,
  //       products: state.highestByBrands,
  //       sortedByLowest: false,
  //       sortedByHighest: true,
  //       sortedByRelevans: false,
  //     }
  //   }
  //   return {
  //     ...state,
  //     products: state.highestPrice,
  //     sortedByLowest: false,
  //     sortedByHighest: true,
  //     sortedByRelevans: false,
  //   }
  // }

  return { ...state }
}

export default reducer
